import React, { useContext, useEffect } from "react";
import { graphql, PageProps } from "gatsby";

import { useLanguageContext, LANGUAGE_ACTIONS } from "@/context";
import { useStoryblok } from "@/base/lib/storyblok";
import StoryblokComponent from "@/base/utils/connectSbComponent";
import Layout from "../layouts/Layout";

interface PageData {
  layout: StoryblokStory;
}

export default function Page({
  pageContext,
  location,
  data,
}: PageProps<PageData, PageContext<PageTemplate>>) {
  const { lang, languages, story, alternates } = pageContext;
  const { dispatch } = useContext(useLanguageContext);

  const { layout } = data;
  const pageStory = useStoryblok<PageTemplate>(story, location);
  const { content } = pageStory;
  const { metadata } = content;
  const parsedLayoutContent = JSON.parse(layout.content) as LayoutTemplate;

  const { header, footer } = parsedLayoutContent || {};

  if (story == null) {
    return (
      <div>
        <h3>Editor page</h3>
      </div>
    );
  }

  useEffect(() => {
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguage,
      payload: lang,
    });
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguages,
      payload: languages,
    });
  }, []);

  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      seo={{ ...metadata, og_type: "website" }}
      siteName={story.name}
      pathname={location.pathname}
      hash={location.hash}
      alternates={alternates}
    >
      <StoryblokComponent
        blok={content as PageTemplate}
        pathname={location.pathname}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query PageQuery($langRegex: String!) {
    layout: storyblokEntry(
      field_component: { eq: "common_layout" }
      full_slug: { regex: $langRegex }
    ) {
      content
    }
  }
`;
